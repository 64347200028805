import { getRoute } from "~/lib/routes";

/**
 * Guard for checking if the user is authenticated.
 * The check is done on the client side only. If the user is not logged in, the user is redirected to the homepage.
 * If the user is not logged in and a destination is provided, the user is redirected to the destination.
 */
export default defineNuxtRouteMiddleware(async (to) => {
	const { isLoggedIn, refreshUser } = useUser();
	
	await refreshUser()

	if (!isLoggedIn.value) {
		if (to.fullPath.includes("/bestellen")) {
			return navigateTo(getRoute("checkoutLogin"));
		}
		return navigateTo(getRoute("accountLogin"));
	}
});
